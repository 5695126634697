import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/send-email-in-umbraco",
  "date": "2014-06-07",
  "title": "SEND EMAIL IN UMBRACO",
  "author": "admin",
  "tags": ["development", "umbraco", "asp.net"],
  "featuredImage": "feature.jpg",
  "excerpt": "Sending email is a common functionality of any client-facing website. Is it possible to be implemented in Umbraco without a plugin? Yes, and I’m going to demonstrate how to implement such functionality."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In your ASP.NET MVC solution, open App_Code folder. Create a new surface controller called EmailController, extending `}<strong parentName="p">{`SurfaceController`}</strong>{` `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using System.Web;
using System.Web.Mvc;
using System.Net.Mail;

public class AppointmentController : Umbraco.Web.Mvc.SurfaceController
{
}
`}</code></pre>
    <p>{`Add a new method, `}<strong parentName="p">{`SendMail`}</strong>{`. Annotate it with `}<strong parentName="p">{`HttpPost`}</strong>{` attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`[HttpPost]
public int SendMail()
{  
}
`}</code></pre>
    <p>{`Now you have to create a model for this method. Create a folder called `}<strong parentName="p">{`Models`}</strong>{` in App_Code folder and create a new file for this. Let's name it ContactModel. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using System.ComponentModel.DataAnnotations;

public class ContactModel
{
    [Required]
    public string Name { get; set; }
 
    [Required]
    public string Email { get; set; }
     
    [Required]
    public string Message { get; set; }
 
}
`}</code></pre>
    <p>{`This model will consist of 3 properties: Name, Email and Message which are the name of the sender, the email address of the sender,and the body of the message. You could create any property you like here in this model.
Now let's add the functionality to the method. You can use your SMTP details to replace smtp.example.com here. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`[HttpPost]
public int SendMail(ContactModel model)
{    
    if (!ModelState.IsValid)
    {
        try 
        {
            var fromAddress = new MailAddress("abc@example.com", "From Name");
         
            var toAddress = new MailAddress(model.Email, "To Name");

            const string fromPassword = "password";
            string subject = "subject";
            string body = model.Message;

            var smtp = new SmtpClient
                        {
                            Host = "smtp.example.com",
                            Port = 587,
                            EnableSsl = true,
                            DeliveryMethod = SmtpDeliveryMethod.Network,
                            UseDefaultCredentials = false,
                            Credentials = new NetworkCredential(fromAddress, fromPassword)
                        };
   
            using (var message = new MailMessage(fromAddress.Address, toAddress.Address)
                        {
                            Subject = subject,
                            Body = body
                        })
            {
                smtp.Send(message);
                TempData.Add("Success", true);
            }
        }
        catch(Exception ex)
        {
            return -1;   
        }        
    }
    return 1;        
}
`}</code></pre>
    <p>{`Now you can call this method from your client side code and off you go. Happy emailing!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      